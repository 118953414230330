import { Component, OnInit } from '@angular/core';

import { SummaryCount } from '@gravity-angular/components';
import { MatTableDataSource } from '@angular/material/table';
import { UsageService } from '../usage-service/usage-service';
import { Subscription } from 'rxjs';
import { CustomerService } from 'app/shared/services/customer-service/customer.service';
import { CustomTranslatorService } from 'app/shared/services/custom-translator/custom-translator.service';
import { NotificationsService } from '@common/notifications/notifications.service';

export interface StorageElement {
  name: string;
  storage: number;
  rows: number;
}

const ELEMENT_DATA: StorageElement[] = [];

/**
 * Component for displaying table storage table for a customer
 */
@Component({
  selector: 'app-usage-tablestorage',
  templateUrl: './usage-tablestorage.component.html',
  styleUrls: ['./usage-tablestorage.component.scss']
})
export class UsageTablestorageComponent implements OnInit {
  clickEventSubscription: Subscription;
  subscription: Subscription;

  summaryCounts: SummaryCount[];

  loadingData = false;
  dataArray: any[];
  dataSource = new MatTableDataSource<StorageElement>(ELEMENT_DATA);

  tableLength = 0;

  customer_id: string;
  method = 'unload_table_to_s3';
  start_date = '2022-04-19';
  end_date = '2022-05-27';

  displayedColumns: string[] = ['name', 'storage', 'rows'];

  constructor(
    private readonly usageService: UsageService,
    private readonly customerService: CustomerService,
    private readonly notificationsService: NotificationsService,
    public customTranslatorService: CustomTranslatorService
  ) {
    this.clickEventSubscription = this.usageService
      .getClickEvent()
      .subscribe(() => {
        this.updateTableStorage();
      });
  }

  /**
   * Update the table storage data
   */
  updateTableStorage = function () {
    this.summaryCounts = [];
    this.loadingData = true;
    this.usageService.getTableStorage(
      this.customer_id,
      this.method,
      this.start_date,
      this.end_date
    );
    this.loadingData = false;
  };

  /**
   * Initialize the component
   */
  ngOnInit(): void {
    this.subscription = this.usageService.currentStartDate.subscribe(
      startDate => {
        return (this.start_date = startDate);
      }
    );
    this.subscription = this.usageService.currentEndDate.subscribe(endDate => {
      return (this.end_date = endDate);
    });
    // this.subscription = this.usageService.currentStorage.subscribe(storage => this.dataArray = storage)

    // Old Customer drop down (USAGE SCREEN DROPDOWN)
    // this.subscription = this.usageService.currentCustomer.subscribe(customer => this.customer_id = customer)
    // this.subscription = this.customerService.customerSelected$.subscribe(customerSelected$ => this.customer_id = customerSelected$)
    this.subscription = this.customerService.currentCustomer.subscribe(
      utilityValue => {
        return (
          (this.customer_id = utilityValue.customer.customerId.toLowerCase()),
          (this.summaryCounts = []),
          (this.loadingData = false)
        );
      }
    );

    this.subscription = this.usageService.currentStorage.subscribe(storage => {
      return (this.dataSource = storage);
    });
    this.subscription = this.usageService.currentStorageSummary.subscribe(
      storageSummary => {
        return (this.summaryCounts = storageSummary);
      }
    );
    // this.getTableStorage();
    // this.updateTableStorage();

    this.summaryCounts = [];
  }

  /**
   * Destroy the component
   */
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
