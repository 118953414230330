/* eslint-disable camelcase */
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { SummaryCount } from '@gravity-angular/components';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { UsageService } from '../usage-service/usage-service';
import { Subject, Subscription } from 'rxjs';
import { XGSCustomer } from '@common/models/user-management.model';
import {
  AbbrevateTimeZonePipe,
  TimezoneConversionPipe
} from 'app/shared/pipes/shared.pipe';
import { CustomerService } from 'app/shared/services/customer-service/customer.service';
import { CustomTranslatorService } from 'app/shared/services/custom-translator/custom-translator.service';
import { takeUntil } from 'rxjs/operators';

export interface StorageElement {
  rows: number;
  data: number;
  query: string;
  user: string;
  start: string;
  end: string;
  duration: string;
}

const ELEMENT_DATA: StorageElement[] = [];

export interface QueryHistory {
  name: string;
  storage: number;
  rows: number;
}

/**
 * Component for displaying query history table for a customer
 */
@Component({
  selector: 'app-usage-queryhistory',
  templateUrl: './usage-queryhistory.component.html',
  styleUrls: ['./usage-queryhistory.component.scss'],
  providers: [TimezoneConversionPipe, AbbrevateTimeZonePipe]
})
export class UsageQueryhistoryComponent implements OnInit, OnDestroy {
  @ViewChild('paginator') paginator: MatPaginator;
  displayedColumns: string[] = [
    'rows',
    'data',
    'query',
    'user',
    'start',
    'end',
    'duration'
  ];

  clickEventSubscription: Subscription;
  subscription: Subscription;
  summaryCounts: SummaryCount[] = [];
  loadingData = false;
  dataArray: any[] = [];
  dataSource = new MatTableDataSource<StorageElement>(ELEMENT_DATA);

  // i18n strings
  startTimeValuePlaceHolder = $localize`:@@udl-usage_query-history_start:Start Time`;
  endTimeValuePlaceHolder = $localize`:@@udl-usage_query-history_end:End Time`;
  paginatorValuePlaceHolder = $localize`:@@udl-usage_query-history_page:Select page of queries`;

  customer_id: string;
  xgsCustomer: XGSCustomer;
  method = 'unload_queries_to_s3';
  start_date = '2022-04-19';
  end_date = '2022-05-27';
  onDestroy$: Subject<boolean> = new Subject();

  constructor(
    private readonly usageService: UsageService,
    private readonly customerService: CustomerService,
    public customTranslatorService: CustomTranslatorService
  ) {
    this.clickEventSubscription = this.usageService
      .getClickEvent()
      .subscribe(() => {
        this.updateQueryHistory();
      });
  }

  /**
   * Updates the query history table with the retrived redshift query data.
   */
  updateQueryHistory = function () {
    this.summaryCounts = [];
    this.loadingData = true;
    this.usageService.getQueries(
      this.customer_id,
      this.method,
      this.start_date,
      this.end_date
    );
  };

  /**
   * Initializes the component
   */
  ngOnInit(): void {
    this.getPageLength();
    this.subscription = this.usageService.currentStartDate.subscribe(
      startDate => {
        return (this.start_date = startDate);
      }
    );
    this.subscription = this.usageService.currentEndDate.subscribe(endDate => {
      return (this.end_date = endDate);
    });

    this.subscription = this.customerService.currentCustomer.subscribe(
      utilityValue => {
        this.xgsCustomer = utilityValue.customer;
        this.customer_id = utilityValue.customer.customerId.toLowerCase();
        this.summaryCounts = [];
        this.loadingData = false;
      }
    );

    this.subscription = this.usageService.currentQueryHistory.subscribe(
      queryHistory => {
        const preferences = this.usageService.getPreferences();
        this.dataSource.data = queryHistory;
        this.dataSource.paginator = this.paginator;
        if (preferences?.pageSize) {
          this.dataSource.paginator.pageSize = preferences.pageSize;
        }
      }
    );

    this.subscription = this.usageService.currentQuerySummary.subscribe(
      querySummary => {
        return (this.summaryCounts = querySummary);
      }
    );
    this.dataSource.data = this.dataArray;
    this.dataSource.paginator = this.paginator;
    const preferences = this.usageService.getPreferences();
    if (this.dataSource.paginator && preferences?.pageSize) {
      this.dataSource.paginator.pageSize = preferences.pageSize;
    }
  }

  /**
   * Gets the page size for the table
   * @returns page size
   */
  getPageSize(): number {
    const preferences = this.usageService.getPreferences();

    return preferences?.pageSize ? preferences.pageSize : 25;
  }

  /**
   * Unsubscribes from all subscriptions
   */
  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.onDestroy$.next(true);
    this.onDestroy$.unsubscribe();
  }

  /**
   * updates value of page size for session
   * @param event mat-paginator event
   */
  setPageLength(event: PageEvent): void {
    this.usageService.updateUsagePreferences('pageSize', event.pageSize);
    this.usageService.updatedUsagePageSize(event);
  }

  /**
   * Gets previous and new values selected to updated mat-paginator
   */
  getPageLength(): void {
    this.usageService.usagePageSize
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(pageEvent => {
        if (pageEvent) {
          this.usageService.setScreenPaginators(
            [this.paginator],
            pageEvent,
            'usage'
          );
        }
      });
  }
}
